import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useViewportAnimations } from '../hooks/hooks';
import Separator from '../components/Separator';
import Cta from '../components/Cta';
import Layout from '../components/layout/Layout';
import Person from '../components/Person';
import Map from '../components/Map';
import TextBlock from '../components/TextBlock';
import Service from '../components/Service';
import Util from '../Util';
import reynaldo from '../images/reynaldo.jpg';
import iso from '../images/iso.png';
import isocert from '../images/iso-cert.jpg';

const colors = {
  from: '#0049E4',
};

export default () => {
  useViewportAnimations();
  const { t } = useTranslation();
  const isMobile = Util.isMobile();

  return (
    <Layout>
      <Service
        colors={colors}
        title={t('Nosotros')}
        description={
          <Trans
            i18nKey="about"
            components={{
              a: (
                <a
                  href="http://www.pgyk.com.ar/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'underline' }}
                >
                  PGK Consultores
                </a>
              ),
              p: <p className="mt-6" />,
            }}
          />
        }
      />
      <section>
        <Map style={{ position: 'relative', marginTop: 80, opacity: 1 }} mapStyle="dark" />
      </section>
      <Separator title={t('Nuestros Especialistas')} />
      <section>
        <div className="container mx-auto lg:px-24" style={{ marginTop: isMobile ? 100 : 0 }}>
          <Person
            name="Reynaldo Cartaña"
            title={<Trans i18nKey="reynaldoTitle" />}
            image={reynaldo}
            summary={<Trans i18nKey="reynaldoSummary" />}
            description={<Trans i18nKey="reynaldoDescription" />}
            linkedin="https://www.linkedin.com/in/reynaldo-carta%C3%B1a-0758a6121/"
          />
        </div>
        <br />
        <TextBlock>
          <Trans
            i18nKey="team"
            components={{
              p: <p className="mt-6" />,
            }}
          />
        </TextBlock>
      </section>
      <Separator title={t('Política de Calidad')} />
      <section>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <div style={{
          width: '20%',
        }}>
          <img src={iso} alt="iso" style={{ width: '100%' }} />
        </div>
      </div>
        <TextBlock>
          <Trans
            i18nKey="politicaCalidad"
            components={{
              p: <p className="mt-6" />,
            }}
          />
        </TextBlock>
        
        <div className="container mx-auto lg:px-24" style={{ textAlign: 'center' }}>
          <img src={isocert} alt="isocert" style={{ width: '100%', marginTop: 50}} />
        </div>      
      </section>
      <Cta />
    </Layout>
  );
};
